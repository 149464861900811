import React from "react";
import { createRoot } from "react-dom/client";
//import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";

import LoginScreen from "./screens/LoginScreen";
import CustomerDetailsScreen from "./screens/CustomerDetailsScreen";
import GymPaymentHistoryScreen from "./screens/GymPaymentHistoryScreen";
import PendingPaymentsScreen from "./screens/PendingPaymentsScreen";
import PrivateRoute from "./components/PrivateRoute";
import GymsScreen from "./screens/GymsScreen";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="" element={<PrivateRoute />}>
        <Route path="/" element={<CustomerDetailsScreen />}></Route>
        <Route path="/availableGyms" element={<GymsScreen />}></Route>
        <Route
          path="/pendingPayments"
          element={<PendingPaymentsScreen />}
        ></Route>
        <Route
          path="/paymentHistory"
          element={<GymPaymentHistoryScreen />}
        ></Route>
      </Route>
    </Route>
  )
);

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
