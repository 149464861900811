import { apiSlice } from "./apiSlice";
import { ADMIN_URL, GYMS_URL, GYM_PAYMENT } from "../constants";

const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGyms: builder.query({
      query: (data) => ({
        url: `${ADMIN_URL}/gyms/existing?${data.query ? data.query : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    createGymId: builder.mutation({
      query: (data) => ({
        url: `${GYMS_URL}/createNewGymId`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getUnUsedGymIds: builder.query({
      query: (data) => ({
        url: `${ADMIN_URL}/gyms/unUsed?${data.query ? data.query : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    adminLogin: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    adminLogout: builder.mutation({
      query: () => ({
        url: `${ADMIN_URL}/logout`,
        method: "POST",
      }),
    }),
    getGymPayments: builder.query({
      query: (data) => ({
        url: `${GYM_PAYMENT}?${data.query ? data.query : ""}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    addGymPayment: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetGymsQuery,
  useAdminLoginMutation,
  useAdminLogoutMutation,
  useGetUnUsedGymIdsQuery,
  useGetGymPaymentsQuery,
  useAddGymPaymentMutation,
  useCreateGymIdMutation,
} = adminApiSlice;
