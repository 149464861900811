import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetGymPaymentsQuery } from "../slices/adminSlice";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";

const GymPaymentHistoryScreen = () => {
  const [searchBy, setSearchBy] = useState("Gym Id");
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(userInfo?.paymentStartDate);

  const [endDate, setEndDate] = useState(userInfo?.paymentEndDate);

  const [getApiQuery, setGetApiQuery] = useState(
    `createdAt[gte]=${startDate}&createdAt[lte]=${endDate}`
  );

  const filterOptions = ["Gym Id", "Txn Id"];

  const dispatch = useDispatch();

  const searchOptions = {
    "Gym Id": "gymId",
    "Txn Id": "transactionId",
  };
  let {
    data: payments,
    isLoading,
    refetch,
    error,
  } = useGetGymPaymentsQuery({
    token: userInfo?.token,
    query: getApiQuery,
  });

  const [totalPayments, setTotalPayments] = useState(
    isLoading ? [] : payments ? payments : []
  );
  const [payentsToList, setPaymentsToList] = useState(
    isLoading ? [] : payments ? payments : []
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setTotalPayments(isLoading ? [] : payments ? payments : 0);
    setPaymentsToList(isLoading ? [] : payments ? payments : 0);
  }, [isLoading, payments]);

  const handleSelect = (e) => {
    setSearchBy(e.target.value);
  };
  const onSearchTextChange = (val) => {
    setSearchValue(val);
    const searchedUsers = payments?.filter((user) => {
      let userData;
      if (searchBy === "Txn Id") {
        userData = String(user[searchOptions[searchBy]]).toLowerCase();
      } else {
        userData = String(user[searchOptions[searchBy]]);
      }
      return userData.includes(val.toLowerCase());
    });
    setPaymentsToList(searchedUsers);
  };

  const handleFilterSelecttion = (val) => {
    setPaymentsToList(val);
  };

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `createdAt[gte]=${startDate}&createdAt[lte]=${endDate}T23:59:59.000Z`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          paymentStartDate: startDate,
          paymentEndDate: endDate,
        })
      );
    }
  };

  return (
    <>
      <h5>Payments List</h5>
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button onClick={handleGetUsers}>Get Data</Button>
          </Form.Group>
        </Col>
      </Row>

      <Row
        className="mb-3"
        style={{
          justifyContent: "center",
          padding: "auto",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col>
          <Form.Group as={Row} className="my-2" controlId="filterBy">
            <Form.Select id="filterBy" value={searchBy} onChange={handleSelect}>
              {filterOptions.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Form.Control
              type="text"
              placeholder="type here to search"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="my-2" controlId="visitorMobile">
            <Button
              type="date"
              placeholder="2023-11-27"
              value={searchValue}
              onChange={(e) => onSearchTextChange(e.target.value)}
            >
              Search
            </Button>
          </Form.Group>
        </Col>
      </Row>
      <Row className="dataRow" style={{ marginBottom: "2%" }}>
        <Button
          className="marginPointEight fifteenWidth buttonGreen numbersButton"
          onClick={() => handleFilterSelecttion(totalPayments)}
        >
          All <strong> {totalPayments?.length}</strong>
        </Button>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error.data.error}</Message>
      ) : (
        <div>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr key="table-header">
                <th>Id</th>
                <th>Paid Amount</th>
                <th>Paid By</th>
                <th>Transaction Id</th>
                <th>Paid Date</th>
              </tr>
            </thead>
            {payentsToList ? (
              <tbody>
                {payentsToList.map((payment) => (
                  <tr key={payment._id}>
                    <td>{payment.gymId}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {payment.paidAmount}
                    </td>
                    <td> {payment.associateId}</td>

                    <td> {payment.transactionId}</td>
                    <td> {payment?.updatedAt.toString().split("T")[0]}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <Message>No users Available</Message>
            )}
          </Table>
        </div>
      )}
    </>
  );
};

export default GymPaymentHistoryScreen;
