import { React, useEffect } from "react";
import { Button, Table } from "react-bootstrap";

import { useSelector } from "react-redux";

import Loader from "../components/Loader";
import Message from "../components/Message";

import {
  useCreateGymIdMutation,
  useGetUnUsedGymIdsQuery,
} from "../slices/adminSlice";
import { toast } from "react-toastify";

const GymsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  let {
    data: gymIds,
    isLoading,
    error,
    refetch,
  } = useGetUnUsedGymIdsQuery({
    token: userInfo?.token,
  });

  const [createGymId] = useCreateGymIdMutation();

  let resData;

  const handleCreateGym = async () => {
    resData = await createGymId({ token: userInfo.token }).unwrap();
    toast.success("Created Gym Id : " + resData.newGymId);
  };

  useEffect(() => {
    refetch();
  }, [refetch, resData]);

  return (
    <>
      <h5>Available Gyms</h5>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error.data.error}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            responsive="sm"
            style={{
              width: "50%",
              margin: "auto",
            }}
          >
            <thead>
              <tr>
                <th>Total : {gymIds.totalGymIds} </th>
              </tr>
            </thead>
            <tbody>
              {gymIds ? (
                gymIds.GymIds.map((gym) => (
                  <>
                    <tr>
                      <td>{gym._id}</td>
                    </tr>
                  </>
                ))
              ) : (
                <Message>
                  No Gyms Available, <br />
                  Please create{" "}
                </Message>
              )}
            </tbody>
          </Table>
          <Button onClick={handleCreateGym}>Create Gym ID</Button>
        </>
      )}
    </>
  );
};

export default GymsScreen;
